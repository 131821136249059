// Variable overrides
@font-face {
  font-family: "GTWThin";
  src: url("../assets/GTWalsheimPro-Thin.eot"); /* IE9 Compat Modes */
  src:
    /* IE6-IE8 */ url("../assets/GTWalsheimPro-Thin.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../assets/GTWalsheimPro-Thin.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("../assets/GTWalsheimPro-Thin.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "GTWRegular";
  src: url("../assets/GTWalsheimPro-Regular.eot"); /* IE9 Compat Modes */
  src:
      /* IE6-IE8 */ url("../assets/GTWalsheimPro-Regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../assets/GTWalsheimPro-Regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("../assets/GTWalsheimPro-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
$theme-colors: (
  "primary": #46a0eb,
  "secondary": #dbebfb,
);
$sidebar-dark-bg: #161f48;
$text-color: red;
$font-family-base: GTWRegular !important;
$body-color: #313949;
.badge-secondary {
  border: 2px solid #46a0eb;
}
.form-control {
  color: #313949 !important;
}

.c-main {
  background-image: url("../assets/fondo\ geometrico\ 5D\ .png");
  background-size: 50px 50px;
  padding: 20px 0px !important;
}
.c-sidebar .c-sidebar-nav-link:hover:hover,
.c-sidebar
  .c-sidebar-minimized
  .c-sidebar-nav-item
  .c-sidebar-nav-link:hover:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover:hover {
  @media (hover: hover), (-ms-high-contrast: none) {
    &:hover {
      background: #4c516f;
    }
  }
}
// CoreUI and its default variables
@import "../../node_modules/@coreui/coreui/scss/coreui";
